import { NavLink } from "react-router-dom";
import React, { Component } from "react";

export class Header extends Component {
    render() {
        return (
            <header>
                <div className="">
                    <header class="text-inherit body-font">
                        <div class="container mx-auto flex flex-wrap flex-col md:flex-row items-center">
                            <NavLink to="/" exact class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                                <img
                                    src="https://res.cloudinary.com/diu7dwrtu/image/upload/v1676477514/bushman_logo_zioe8q.jpg"
                                    alt="logo"
                                    fill="none" stroke="currentColor" class="w-40 h-30 text-black p-1" viewBox="0 0 24 24"
                                />
                            </NavLink>
                            <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
                                <NavLink to="/" className="link text-blue">Home</NavLink>
                                <NavLink to="/gallery" className="link text-blue">Gallery</NavLink>
                                <NavLink to="/pricing" className="link text-blue">Reserve</NavLink>
                            </nav>
                            <a href="https://api.whatsapp.com/send?phone=+254722841654&text=Hello%2C%20%0A%0AWelcome%20to%20Bushman%20Sunset%20House.%0AI%20will%20be%20contacting%20you%20soon.%0A%0AThank%20you.">
                                <button class="inline-flex items-center bg-orange text-blue 
                                        hover:bg-blue hover:text-orange
                                        border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Contact Us
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </header>
                </div>
            </header>
        )
    }
}

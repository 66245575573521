import React, { useState, useEffect } from "react";

export const Carousel = () => {

    const images = [
        "https://res.cloudinary.com/diu7dwrtu/image/upload/v1672851308/twentytwo_mrtktc.png",
        "https://res.cloudinary.com/diu7dwrtu/image/upload/v1672851178/thirty_o4zpks.png",
        "https://res.cloudinary.com/diu7dwrtu/image/upload/v1672849566/fourteen_dvnxep.png",
        "https://res.cloudinary.com/diu7dwrtu/image/upload/v1672849491/eleven_ilzbks.png",
        "https://res.cloudinary.com/diu7dwrtu/image/upload/v1672851207/thirtytwo_uh8ete.png"
    ]

    const [currentIndex, setCurrentIndex] = useState(0);
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        const newIntervalId = setInterval(() => {
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 5000);
        }, 5000);
        setIntervalId(newIntervalId);
        return () => clearInterval(intervalId);
    }, [currentIndex, intervalId, images.length]);

    return (
        <div className="relative">
            <img src={images[currentIndex]} class="absolute inset-0 object-cover w-full h-full" alt="" />
            <div class="backdrop-opacity-5 relative">
                <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl lg:h-1/2 md:px-24 lg:px-8 lg:py-20">
                    <div class="flex flex-col items-center justify-between xl:flex-row">
                        <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
                            <h2 class="max-w-lg mb-6 font-bold tracking-tight text-white lg:text-8xl md:text-5xl sm:text-8xl sm:leading-none">
                                Bushman's Sunset  <br class="hidden md:block" />
                                House<span class="text-white"></span>
                            </h2>
                            <p class="max-w-xl mb-4 text-base text-white md:text-lg">
                                For breathtaking views and varied trails
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { Component } from "react";
import { Footer } from "./Footer";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
    'https://vubvksngvlpjcfbkftcr.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZ1YnZrc25ndmxwamNmYmtmdGNyIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY3NjY5MzgxMSwiZXhwIjoxOTkyMjY5ODExfQ.eATdoHCWPJsHXf-EqGKsH0oBncTVze1ITyvDJtonsP8');


export class Gallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageUrls: [],
        };
    }

    componentDidMount() {
        this.fetchRandomImages();
    }

    fetchRandomImages = async () => {
        try {
            const { data, error } = await supabase
                .storage
                .from("bushman-gallery")
                .list();

            if (error) {
                throw new Error(error.message);
            }

            const imageUrls = [];
            for (const item of data) {
                const imageUrl = await supabase.storage
                    .from("bushman-gallery") // Replace "bucket-name" with your actual Supabase Storage bucket name
                    .getPublicUrl(item.name);

                imageUrls.push(imageUrl);
            }

            this.setState({ imageUrls });
            console.log(imageUrls);

        } catch (error) {
            console.error("Error fetching random images:", error);
        }
    };

    render() {

        const { imageUrls } = this.state;
        return (
            <div className="">
                <section className="py-6 dark:bg-gray-100">
                    <div className="container flex flex-col justify-center p-4 mx-auto">
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 sm:grid-cols-2">
                        {imageUrls.map((imageUrl, index) => (
                            <div className="md:p-2 p-1 lg:w-full" key={index}>
                                <img
                                    alt="gallery"
                                    className="object-cover w-full dark:bg-gray-500 aspect-square"
                                    src={imageUrl.data.publicUrl} // Access the publicURL property of the imageUrl object
                                />
                            </div>
                        ))}
                        </div>
                    </div>
                </section>
                {/* <section className="py-6 dark:bg-gray-200 dark:text-gray-50">
                    <div className="container grid grid-cols-2 gap-4 p-4 mx-auto md:grid-cols-4">
                        {imageUrls.map((imageUrl, index) => (
                            <div className="md:p-2 p-1 lg:w-1/2" key={index}>
                                <img
                                    alt="gallery"
                                    className="w-full object-cover h-full object-center block"
                                    src={imageUrl.data.publicUrl} // Access the publicURL property of the imageUrl object
                                />
                            </div>
                        ))}
                    </div>
                </section> */}
                <Footer />
            </div>
        )
    }
}
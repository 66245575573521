import React, { Component } from "react";
// import { Booking } from "./Booking";
import { Footer } from "./Footer";

export class Pricing extends Component {
    render() {
        return (
            <div>
                {/* <Booking/> */}
                <div class="items-center">
                    <p class="container mx-auto flex flex-col items-center text-center justify-center lg:w-1/2 sm:w-full md:w-3/4 p-2">
                        Our home boasts six bedrooms,
                        four of which are ensuite and two of which share bathrooms.
                        With a maximum capacity of 12 clients, our rental is perfect for family groups,
                        filming sites, vlogging and training. Take advantage of our private running trails,
                        or relax on the large balconies overlooking the stunning Nguruman hills and Magadi plains.
                    </p>
                    <p class="container mx-auto flex flex-col items-center text-center justify-center lg:w-1/2 sm:w-full md:w-3/4 p-2">
                        All of our accommodations are self-catering, but a cook is available at an additional cost.
                        Contact us today to book your stay and experience the best of Kenyan hospitality!
                    </p>
                </div>
                <section class="py-6 my-20 sm:my-10 dark:bg-gray-100">
                    <div class="container mx-auto flex flex-col lg:flex-row md:flex-row
                        items-center justify-center max-w-lg p-4 lg:max-w-full sm:p-10 lg:flex-row">
                        <div class="flex flex-col items-center justify-center flex-1 p-4 pb-8 sm:p-8 lg:p-16 
                            bg-blue text-orange">
                            <span class="text-sm">Rate Per Room</span>
                            <h1 class="text-5xl font-bold text-center">KES 3,500</h1>
                            <p class="font-semibold text-center">
                                Individual room, maximum of 2 persons
                            </p>
                            <p class="mt-3">
                                <a class="btn p-1 mx-1 bg-orange text-blue rounded-md font-bold" href="https://www.booking.com/hotel/ke/bushman-sunset-house-nairobi.en-gb.html?aid=356980&label=gog235jc-1DCAsodkIcYnVzaG1hbi1zdW5zZXQtaG91c2UtbmFpcm9iaUgzWANodogBAZgBCbgBF8gBDNgBA-gBAYgCAagCA7gChayVpAbAAgHSAiRiMjk1OTI5YS1hNzliLTQ4OWUtODY0Yi04YzFkNzZmYTU1ZWLYAgTgAgE&sid=f59f1033dfa3ba622bd5a47c11c5efb2&dist=0&keep_landing=1&sb_price_type=total&type=total&">Booking.Com</a> 
                                <a class="btn p-1 mx-1 bg-orange text-blue rounded-md font-bold" href="https://www.airbnb.cn/seoroom/gdsk7m75jxtg">AirBnB</a>
                            </p>
                        </div>
                        <div class="flex flex-col items-center justify-center flex-1 p-4 pb-8 text-center rounded-md sm:p-8 lg:p-16 
                        bg-orange text-blue">
                            <span class="text-sm font-semibold">Entire House</span>
                            <h1 class="text-5xl font-bold">KES 15,000</h1>
                            <p class="font-semibold">
                                All 6 rooms, maximum of 12 persons
                            </p>
                            <p class="mt-3">
                                <a class="btn p-1 mx-1 bg-blue text-orange rounded-md font-bold" href="https://www.booking.com/hotel/ke/bushman-sunset-house-nairobi.en-gb.html?aid=356980&label=gog235jc-1DCAsodkIcYnVzaG1hbi1zdW5zZXQtaG91c2UtbmFpcm9iaUgzWANodogBAZgBCbgBF8gBDNgBA-gBAYgCAagCA7gChayVpAbAAgHSAiRiMjk1OTI5YS1hNzliLTQ4OWUtODY0Yi04YzFkNzZmYTU1ZWLYAgTgAgE&sid=f59f1033dfa3ba622bd5a47c11c5efb2&dist=0&keep_landing=1&sb_price_type=total&type=total&">Booking.Com</a> 
                                <a class="btn p-1 mx-1 bg-blue text-orange rounded-md font-bold" href="https://www.airbnb.cn/seoroom/gdsk7m75jxtg">AirBnB</a>
                            </p>
                        </div>
                    </div>
                </section>
                <div className="mt-10"></div>
                <Footer />
            </div>
        )
    }
}




import React, { Component } from "react";
import { About } from "./About";
import { Footer } from "./Footer"
import { Carousel } from "./Carousel"

export class Home extends Component {
    render() {
        return (
            <div>
                <Carousel/>
                <About />
                <Footer />
            </div>
        )
    }
}


import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { DisplayImage } from "./DisplayImage";

export class About extends Component {
    render() {
        return (
            <div className="about-page">
                <div class="p-3">
                    <section class="bg-white dark:bg-gray-100">
                        <div class="container px-5 py-5 mx-auto">
                            <div class="lg:-mx-4 lg:flex lg:items-center">
                                <img
                                    class="object-cover object-center lg:w-1/2 lg:mx-6 w-full h-96 rounded-lg lg:h-[30rem]"
                                    src="https://res.cloudinary.com/diu7dwrtu/image/upload/v1672850100/one_vfuh6f.png"
                                    alt=""
                                />
                                <div class="mt-8 lg:w-full lg:px-6 lg:mt-0">
                                    <p class="text-5xl font-semibold text-grey-500 ">“</p>
                                    <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl lg:w-full">
                                        Nature's canvas at its finest
                                    </h1>
                                    <p class="lg:max-w-lg my-2 text-gray-700 dark:text-gray-600 text-xl ">
                                        Escape to the peaceful and picturesque setting of
                                        Bushman's Sunset House in Kajiado county. Nestled in
                                        the rolling hills, this stunning vacation home offers
                                        the perfect vantage point to witness the breathtaking
                                        beauty of a sunset. Imagine sipping a refreshing drink
                                        as you watch the sun dip below the horizon, painting the
                                        sky with a stunning palette of oranges, pinks, and purples.
                                        Don't miss this opportunity to experience the serenity and
                                        splendor of a sunset like no other. Book your stay at
                                        Bushman's Sunset House today and create memories that
                                        will last a lifetime.
                                    </p>
                                    <NavLink to="pricing/">
                                        <button class="inline-flex items-center bg-blue text-orange border-0 py-1 px-3 focus:outline-none 
                                        hover:bg-orange hover:text-blue rounded text-base mt-4 md:mt-0">Book Now
                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="bg-white dark:bg-gray-100">
                        <div class="container px-5 py-5 mx-auto">
                            <div class="lg:-mx-4 lg:flex lg:items-center">
                                <div class="mt-8 lg:w-full lg:px-6 lg:mt-0">
                                    <p class="text-5xl font-semibold text-grey-500 ">“</p>
                                    <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl lg:w-full">
                                        A Runners' Retreat near Ngong Hills
                                    </h1>
                                    <p class="lg:max-w-lg my-4 text-gray-700 dark:text-gray-600 text-xl ">
                                        An athlete's paradise! Our vacation rental is located just 3km from the stunning Ngong Hills,
                                        a popular destination for world-class athletes to train. Our property boasts
                                        a variety of running trails that cater to all levels of ability,
                                        from leisurely jogs to intense interval training.
                                        Wake up to fresh air and peaceful surroundings as you gear up
                                        for an invigorating run. And when you're not hitting the trails,
                                        relax in our spacious and comfortable rental home.
                                        Don't miss this opportunity to make the most of your training
                                        while on vacation. Book your stay at our athlete's paradise
                                        today and elevate your training to the next level!
                                    </p>
                                    <NavLink to="pricing/">
                                        <button class="inline-flex items-center bg-blue text-orange border-0 py-1 px-3 focus:outline-none 
                                        hover:bg-orange hover:text-blue rounded text-base mt-4 md:mt-0">Book Now
                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </button>
                                    </NavLink>
                                </div>
                                <img
                                    class="object-cover object-center lg:w-1/2 lg:mx-6 w-full mt-2 h-96 rounded-lg lg:h-[30rem]"
                                    src="https://res.cloudinary.com/diu7dwrtu/image/upload/v1672851221/twentyeight_zkvs8r.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </section>
                    <section class="bg-white dark:bg-gray-100">
                        <div class="container px-5 py-5 mx-auto">
                            <div class="lg:-mx-4 lg:flex lg:items-center">
                                <img
                                    class="object-cover object-center lg:w-1/2 lg:mx-6 w-full h-96 rounded-lg lg:h-[30rem]"
                                    src="https://res.cloudinary.com/diu7dwrtu/image/upload/v1672849491/eleven_ilzbks.png"
                                    alt=""
                                />
                                <div class="mt-8 lg:w-full lg:px-6 lg:mt-0">
                                    <p class="text-5xl font-semibold text-grey-500 ">“</p>
                                    <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl lg:w-full">
                                        Relax in comfort
                                    </h1>
                                    <p class="lg:max-w-lg my-4 text-gray-700 dark:text-gray-600 text-xl ">
                                        Find your escape at Bushman's Sunset House - luxurious rooms,
                                        stunning sunset views, and a fully-equipped kitchen and
                                        roasting patio await you. Relax in comfort with plush queen-size beds,
                                        crisp linens, and fluffy pillows, and make yourself at home with
                                        access to our fully-equipped kitchen and cozy roasting patio.
                                        Enjoy the beauty of nature and the tranquility of the surrounding
                                        area while you unwind and recharge.
                                        Experience the ultimate relaxation and rejuvenation at this oasis in Kajiado.
                                    </p>
                                    <NavLink to="pricing/">
                                        <button class="inline-flex items-center bg-blue text-orange border-0 py-1 px-3 focus:outline-none 
                                        hover:bg-orange hover:text-blue rounded text-base mt-4 md:mt-0">Book Now
                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <DisplayImage />
            </div>
        )
    }
}


import React, { Component } from "react";

export class DisplayImage extends Component {
    render() {
        return (
            <div class="object-cover object-center">
                <img src="https://res.cloudinary.com/diu7dwrtu/image/upload/v1672849436/eighteen_c9qxhg.png" alt="bushman-sunset-house"/>
            </div>
        )
    }
}